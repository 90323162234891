import { FC, ReactText, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { getHighestRole, Roles } from '../../models/Role';
import User from '../../models/User';
import UserService from '../../services/UserService';
import { Option } from '../Option';
import PermissionUserDetailItem from './PermissionUserDetailItem';
import { BreadCrumbBackButton } from '../shared/breadcumb/BreadCrumbBackButton';
import ContextMenu, { ContextMenuItem } from '../shared/ContextMenu';
import DropdownSelect, { DropdownSize } from '../shared/form-control/DropdownSelect';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import PageLoader from '../shared/page-loader/PageLoader';
import ProfileImage, { ImageSize } from '../shared/profile-image/ProfileImage';
import StatusTag, { StatusVariant } from '../shared/tags/StatusTag';
import Checkbox from '../shared/form-control/Checkbox';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import Tooltip from '../shared/Tooltip';
import usePermissions from '../../hooks/permissions/usePermissions';
import { ModalContext } from '../../contexts/ModalContext';

export interface PermissionSingleUserProps {
  user: User;
  onBack: () => void;
  permissions: Option<string, string>[];
  fetchUsers: () => void;
}

const PermissionSingleUser: FC<PermissionSingleUserProps> = ({ user, onBack, permissions, fetchUsers }) => {
  const [permission, setPermission] = useState<Option<string, string> | null>(null);
  const [modal, setModal] = useState<JSX.Element | null>(null);
  const [loading, setLoading] = useState(false);
  const [hasArchitectRole, setHasArchitectRole] = useState(false);
  const hasPermission = usePermissions();
  const { t } = useTranslation('common');
  const toasts = useToasts();
  const filteredPermissions = useMemo(() => {
    return permissions.filter((p) => hasPermission(p.value));
  }, [hasPermission, permissions]);
  const canDeactivateUser = useMemo(() => {
    return permission && hasPermission(Roles.TeamLead) && hasPermission(permission.value);
  }, [hasPermission, permission]);
  const currentClient = useRecoilValue(currentClientAtom);

  const fetchUserRole = useCallback(async () => {
    try {
      setLoading(true);
      if (user.id) {
        const { data } = await UserService.getUserRoles(user.id, currentClient?.id || '');
        if (data.length) {
          const highestRoleCode = getHighestRole(data);
          const highestRole = data.find((x) => x === highestRoleCode);

          if (highestRole) {
            if (user?.isArchitect) {
              setHasArchitectRole(true);
            }

            setPermission({
              id: highestRole,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              text: t(`roles.${highestRole}` as any),
              value: highestRole,
            });
          }
        }
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [currentClient?.id, t, user]);

  useEffect(() => {
    fetchUserRole();
  }, [fetchUserRole]);

  const userStatus = useMemo(() => {
    return user.active ? (user.verified ? t('user-status.enabled') : t('user-status.pending')) : t('user-status.deactivated');
  }, [t, user.verified, user.active]);

  const safeAction = (newStatus: string, onConfirm: () => void) => {
    setModal(
      <ModalContext.Provider value={{ open: true }}>
        <ConfirmationModal
          title={t('permissions.user.modals.status.title')}
          description={
            <Trans
              t={t}
              i18nKey="permissions.user.modals.status.description"
              values={{
                user: user.firstName || t('permissions.user.modals.this-user'),
                oldStatus: userStatus,
                newStatus: newStatus,
              }}
              components={{ bold: <strong /> }}
            />
          }
          confirmText={t('permissions.user.modals.status.title')}
          onCancel={() => setModal(null)}
          onConfirm={() => {
            setModal(null);
            onConfirm();
          }}
        />
      </ModalContext.Provider>,
    );
  };

  const deactivate = (userId: string) => {
    const onConfirm = () =>
      UserService.disableUser(userId).then(() => {
        toasts.addToast({ title: t('permissions.toasts.disabled'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        fetchUsers();
      });

    safeAction(t('user-status.deactivated'), onConfirm);
  };

  const activate = (userId: string) => {
    const onConfirm = () =>
      UserService.enableUser(userId).then(() => {
        toasts.addToast({ title: t('permissions.toasts.enabled'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        fetchUsers();
      });
    safeAction(t('user-status.enabled'), onConfirm);
  };

  const resendInvite = (userId: string) => {
    UserService.resendInvite(userId, currentClient?.id || '').then(() => {
      toasts.addToast({ title: t('permissions.toasts.resent-invite'), type: ToastType.SUCCESS, expiresInMs: 5000 });
    });
  };

  const contextItemsFor = (user: User) => {
    if (!user.id) {
      return [];
    }

    const actions: ContextMenuItem[] = [];
    if (user.active) {
      actions.push({ title: t('permissions.context-menu.disable'), onClick: () => deactivate(user.id || '') });

      if (!user.verified) {
        actions.push({ title: t('permissions.context-menu.resend-invite'), onClick: () => resendInvite(user.id || '') });
      }
    } else {
      actions.push({ title: t('permissions.context-menu.enable'), onClick: () => activate(user.id || '') });
    }

    return actions;
  };

  const handlePermissionSelect = (data: Option<string, ReactText>) => {
    if (JSON.stringify(data) === JSON.stringify(permission)) return;

    setModal(
      <ModalContext.Provider value={{ open: true }}>
        <ConfirmationModal
          title={t('permissions.user.modals.permission-access.title')}
          description={
            <Trans
              t={t}
              i18nKey="permissions.user.modals.permission-access.description"
              values={{
                user: user.firstName || t('permissions.user.modals.this-user'),
                oldAccess: permission?.text,
                newAccess: data.text,
              }}
              components={{ bold: <b /> }}
            />
          }
          confirmText={t('permissions.user.modals.permission-access.title')}
          onCancel={() => setModal(null)}
          onConfirm={async () => {
            if (user.id)
              await UserService.updateRole(user.id, currentClient?.id as string, data.value as string)
                .then(() => {
                  toasts.addToast({
                    type: ToastType.SUCCESS,
                    title: '',
                    description: t('permissions.user.toasts.permission-changed'),
                    expiresInMs: 2000,
                  });
                  setPermission(data as Option<string, string>);
                })
                .catch((err) => {
                  if (err?.meta?.message) {
                    toasts.addToast({ type: ToastType.ERROR, title: 'Error', description: err.meta.message, expiresInMs: 2000 });
                  }
                  console.error(err);
                })
                .finally(() => setModal(null));
          }}
        />
      </ModalContext.Provider>,
    );
  };

  const showArchitectModal = () => {
    setModal(
      <ModalContext.Provider value={{ open: true }}>
        <ConfirmationModal
          title={t(`permissions.user.modals.builder-access.${!hasArchitectRole ? 'grant' : 'revoke'}.title`)}
          description={t(`permissions.user.modals.builder-access.${!hasArchitectRole ? 'grant' : 'revoke'}.description`, {
            user: user.firstName || t('permissions.user.modals.this-user'),
          })}
          confirmText={t(`permissions.user.modals.builder-access.${!hasArchitectRole ? 'grant' : 'revoke'}.title`)}
          onCancel={() => setModal(null)}
          onConfirm={async () => {
            if (user.id)
              await UserService.updateArchitectPermission(user.id, !hasArchitectRole)
                .then(() => {
                  setHasArchitectRole(!hasArchitectRole);
                })
                .catch((err) => {
                  if (err?.meta?.message)
                    toasts.addToast({
                      type: ToastType.ERROR,
                      title: 'Error',
                      description: err.meta.message,
                      expiresInMs: 2000,
                    });
                  console.error(err);
                })
                .finally(() => setModal(null));
          }}
        />
      </ModalContext.Provider>,
    );
  };

  return (
    <>
      {modal}
      <div className="py-4">
        <div className="flex items-center gap-4 py-4">
          <BreadCrumbBackButton onClick={onBack} />{' '}
          <div className="flex items-center space-x-4">
            <ProfileImage user={user} size={ImageSize.M} />
            <div className="text-dpm-32 my-2 font-medium text-black">
              {user.firstName || user.lastName ? `${user.firstName || ''} ${user.lastName || ''}` : '-'}
            </div>
          </div>
        </div>

        {loading ? (
          <PageLoader loading />
        ) : (
          <div className="flex flex-col">
            <PermissionUserDetailItem title={t('permissions.user.sections.email')} content={user.email} />
            {permission && (
              <PermissionUserDetailItem
                title={t('permissions.user.sections.permission-access')}
                content={
                  // user must own same permission as user and must have other permissions to delegate
                  hasPermission(permission.value) && filteredPermissions.length > 1 ? (
                    <div>
                      <DropdownSelect
                        data-cy={`user-permission-options-${user.id}`}
                        className="max-w-xs border-none"
                        size={DropdownSize.S}
                        value={permission}
                        onChange={handlePermissionSelect}
                        options={filteredPermissions}
                        placeholder={t('permissions.user.inputs.permission')}
                        menuPosition="right"
                      />
                    </div>
                  ) : (
                    <span>{permission.text}</span>
                  )
                }
              />
            )}
            <PermissionUserDetailItem
              title={t('permissions.user.sections.account-status')}
              content={
                <div className="-mr-8">
                  {user.active ? (
                    user.verified ? (
                      <StatusTag data-cy={`user-status-${user.id}`} statusType={StatusVariant.GREEN} text={t('user-status.active')} />
                    ) : (
                      <StatusTag data-cy={`user-status-${user.id}`} statusType={StatusVariant.ORANGE} text={t('user-status.pending')} />
                    )
                  ) : (
                    <StatusTag data-cy={`user-status-${user.id}`} statusType={StatusVariant.GRAY} text={t('user-status.blocked')} />
                  )}
                </div>
              }
              action={
                <div className="ml-4">
                  {canDeactivateUser && <ContextMenu data-cy={`user-contect-menu-${user.id}`} items={contextItemsFor(user)} />}
                </div>
              }
            />
            {hasPermission(Roles.ConsultantManager) && (
              <PermissionUserDetailItem
                title={t('permissions.user.sections.architect-role')}
                content={
                  <Tooltip text={permission?.value === Roles.SuperAdmin ? t('permissions.user.labels.super-admin-access') : undefined}>
                    {(tooltip) => (
                      <div {...tooltip}>
                        <Checkbox
                          data-cy={`architect-checkbox-${user.id}`}
                          value={hasArchitectRole}
                          label={t('permissions.user.labels.grant-access')}
                          labelBeforeCheckbox
                          onChange={() => showArchitectModal()}
                          disabled={permission?.value === Roles.SuperAdmin}
                        />
                      </div>
                    )}
                  </Tooltip>
                }
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PermissionSingleUser;
